/**
 * Created by louidorpc on 9/30/20.
 */
import React from 'react'
import {FacebookProvider, CommentsCount, Page, Comments, CustomChat,Like} from 'react-facebook';


export const FacebookPage = () => (

    <FacebookProvider appId="789650755153550" style={{height: '100px', width: '100%'}}>
        <Page href="https://www.facebook.com/CorrectProHT/" tabs="timeline" height="200px"
              width="500px"/>
    </FacebookProvider>

);

export const FacebookComment = () => (
    <FacebookProvider appId="789650755153550">
        <Comments href="https://www.facebook.com/CorrectProHT/"/>
    </FacebookProvider>
);


export const FacebookLikeButton = () => (
    <FacebookProvider appId="789650755153550">
        <Like href="https://www.facebook.com/CorrectProHT/" olorScheme="dark" showFaces share width="100px"/>
    </FacebookProvider>
);