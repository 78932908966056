/**
 * Created by louidorpc on 7/6/20.
 */
import React from 'react'

const Pathners = () => (
    <section class="partners-section">
        <h2 class="hidden">Partners</h2>
        <div class="container">
            <div class="row">
                <div class="col col-xs-12">
                    <div class="partners-slider">
                        <div class="grid">
                            <img src="assets/images/clogo.png"  width="50px" alt/>
                        </div>
                        <div class="grid">
                            <img src="assets/images/clogo.png" alt/>
                        </div>
                        <div class="grid">
                            <img src="assets/images/clogo.png" alt/>
                        </div>
                        <div class="grid">
                            <img src="assets/images/clogo.png" alt/>
                        </div>
                        <div class="grid">
                            <img src="assets/images/clogo.png" alt/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export  default Pathners;