/**
 * Created by louidorpc on 7/6/20.
 */
import React from 'react'
import PageTitle from '../pageTitle'
import  {FacebookPage} from '../facebook'


const Edition = () => (

    <>
    <PageTitle title="Édition" home="Acceuil"/>
    <section class="about-section-s5 section-padding">
        <div class="container">

            <div class="row">
                <div class="col col-md-7">
                    <div class="about-details">
                        <h4>PROCÉDURE ÉDITORIALE DE PRO ÉDITIONS</h4>
                        <p>
                            Vous souhaitez publier votre livre ? Pro Éditions veut être à vos côtés pendant tout le
                            processus éditorial avec discipline, passion et professionnalisme. De la création à la
                            commercialisation, l’édition d’un manuscrit se déroule en différentes étapes, dont voici les
                            cinq(5) principales : </p>
                        <h4>Envoi de manuscrit à notre jury ou comité de lecture </h4>
                        <p>
                            Tous les manuscrits que nous recevons sont rigoureusement lus et sélectionnés par notre jury
                            ou comité de lecture, constitué de spécialistes de l’édition passionnés par la littérature.
                            Vous recevrez une réponse rapidement, dans un délai d'une semaine environ. Si votre
                            manuscrit est retenu, vous serez contacté-ée un contrat de publication vous sera envoyé très
                            vite .
                        </p>
                        <h4>Correction de votre manuscrit par nos biographes</h4>
                        <p>Cette étape permet d’apporter un regard extérieur à votre ouvrage et de corriger les
                            éventuelles fautes d’orthographe, de grammaire et de syntaxe. Confier votre manuscrit à nos
                            biographes est la garantie de publier un livre idéal sur le fond avec une ligne éditoriale
                            sans faille.</p>

                        <h4>Création de votre première de couverture par des professionnels de l’infographie</h4>
                        <p>
                            La couverture d’un livre est un des éléments de marketing les plus importants qui peut
                            influencer le consommateur. Confier votre manuscrit à nos infographistes est la garantie
                            d’obtenir une couverture soignée et personnalisée, qui contribuera fortement à son
                            succès.<br></br>
                            Notre équipe éditoriale s’occupe ensuite de l’harmonisation de votre texte afin de rendre sa
                            lecture fluide et agréable. Une fois la mise en page effectuée, Pro Éditions vous envoie
                            votre livre finalisé pour validation ou corrections éventuelles de votre part. Si le livre
                            correspond à vos attentes, il est édité au format papier et numérique.
                        </p>

                        <h4>Dépôt légal et ISBN (gratuit)</h4>
                        <p>Une fois le bon à tirer (BAT) validé, votre livre sera publié. Conformément à la législation,
                            deux (2) numéros ISBN lui seront ensuite attribués, pour sa version papier et sa version
                            numérique. Votre ouvrage sera, à ce moment, prêt à être distribué.</p>
                        <h4>Commercialisation</h4>
                        <p>La dernière étape est la vente de votre livre auprès de nos différents locaux</p>
                    </div>


                </div>

                <div class="col col-md-5">
                    <div class="case-study-sidebar">
                        <div class="faq">
                            <div className="about-section-s2 section-padding">
                                <div className="video">
                                    <img src="assets/images/about.jpeg" alt=""/>
                                    <a href="/assets/correctpresentation.mp4?autoplay=1" class="video-btn"
                                       data-type="iframe"><i class="fa fa-play"></i></a>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-12 section-padding">
                            <FacebookPage/>

                        </div>
                        <div class="widget contact-widget">
                            <h3>Contact</h3>
                            <ul>
                                <li><i class="fa fa-location-arrow"></i>24, Impasse Racine, Santo 11,
                                    Croix-des-Bouquets, Haïti
                                </li>
                                <li><i class="fa fa-location-arrow"></i>
                                    4,Juvénat Juvénat (Pétion-Ville, Haïti) / Entrée Karibe Hôtel
                                </li>
                                <li><i class="fa fa-phone"></i><a href="tel:+5093121-5441"> (+509) 3121-5441 <br></br>
                                    3422-0571</a></li>
                                <li><i class="fa fa-envelope"></i> <a href="mailto:editions@correctpro.net">editions@correctpro.net</a>
                                </li>
                            </ul>
                        </div>
                        <div class="widget brochures-widget">
                            <h3>Brochures</h3>
                            <div class="btns">
                                <a href="#"><i class="fa fa-download"></i> Télécharger la brochure</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    </>


)

export  default Edition;