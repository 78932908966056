/**
 * Created by louidorpc on 7/6/20.
 */
import React from 'react'
import PageTitle from '../pageTitle'
import  {FacebookPage} from '../facebook'

const About = () => (

    <>
    <PageTitle title="À propos" home="Acceuil"/>
    <section class="about-section-s5 section-padding">
        <div class="container">

            <div class="row">
                <div class="col col-md-7">
                    <div class="about-details">
                        <h3>Mission</h3>
                        <p>
                            Création en Haïti d'une génération de jeunes écrivains.
                        </p>
                        <h3>Description de CORRECTPRO </h3>
                        <p>
                            CORRECTPRO est une entreprise fondée le 10 juin 2020 qui évolue dans le secteur
                            Édition/Imprimerie. Elle est reconnue par l'État haïtien et est dûment enregistrée au
                            ministère du Commerce et de l’Industrie. Nous avons aussi notre première patente mais de
                            forme juridique entreprise individuelle. Cette entreprise se veut un espace éditorial qui
                            héberge des auteurs de toute part désireux d'expérimenter autrement les travaux littéraires.
                            C'est dans cette optique que le slogan « Lire, Écrire et Apprendre » a été adopté.
                            CORRECTPRO a la prétention de venir combler utilement ce secteur jugé en manque
                            d'exploitation ou mal exploité. En ce sens, il a été décidé que CORRECTPRO vienne aider dans
                            la dynamisation d'une autre culture éditoriale en Haïti.
                        </p>


                    </div>


                    <div class="about-details">
                        <div class="case-title">
                            <h3>Étude de marché</h3>
                        </div>
                        <div class="img-holder">
                            <img src="assets/images/etude.png" alt="" class="img img-responsive"/>
                        </div>

                        <h5>
                            CORRECTPRO a décidé de se lancer dans le secteur de l’Édition et de l’Imprimerie pour la
                            raison suivante:</h5>

                        <p>Le marché potentiel que nous ciblons et dont nous avons vu les opportunités d’exploitation
                            n’a pas été utilisé habilement par aucune des entreprises d’Édition sur le marché. À
                            CORRECTPRO, nous avons décidé de promouvoir et de mettre en avant les jeunes écrivains et
                            auteurs haïtiens. </p>
                        <h6>Pourquoi ? </h6>
                        <p>Selon un sondage mené auprès des jeunes écrivains et auteurs, et des enquêtes dans les
                            Éditions du pays, nous avons constaté que les Éditions ne veulent pas produire des jeunes
                            qui n’ont pas : </p>
                        <ul>
                            <li> Une lignée de « grande famille »</li>
                            <li>d’argent pour assurer les dépenses et/ou éventuelles pertes ;</li>
                            <li> un lien direct avec l’entreprise, c’est-à-dire amical ou familial.</li>
                        </ul>
                    </div>
                </div>

                <div class="col col-md-5">
                    <div class="case-study-sidebar">
                        <div class="faq">
                            <div className="about-section-s2 section-padding">
                                <div className="video">
                                    <img src="assets/images/about.jpeg" alt=""/>
                                    <a href="/assets/correctpresentation.mp4?autoplay=1" class="video-btn"
                                       data-type="iframe"><i class="fa fa-play"></i></a>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-12 section-padding">
                            <FacebookPage/>

                        </div>
                        <div class="widget contact-widget">
                            <h3>Contact</h3>
                            <ul>
                                <li><i class="fa fa-location-arrow"></i>24, Impasse Racine, Santo 11,
                                    Croix-des-Bouquets, Haïti
                                </li>
                                <li><i class="fa fa-location-arrow"></i>
                                    4,Juvénat Juvénat (Pétion-Ville, Haïti) / Entrée Karibe Hôtel
                                </li>
                                <li><i class="fa fa-phone"></i><a href="tel:+5093121-5441"> (+509) 3121-5441 <br></br>
                                    3422-0571</a></li>
                                <li><i class="fa fa-envelope"></i> <a href="mailto:editions@correctpro.net">editions@correctpro.net</a>
                                </li>
                            </ul>
                        </div>
                        <div class="widget brochures-widget">
                            <h3>Brochures</h3>
                            <div class="btns">
                                <a href="#"><i class="fa fa-download"></i> Télécharger la brochure</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>

    </>


)

export  default About;