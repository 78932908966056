import types from "../actions/type";

const defaultState = {
  locale: "fr"
};

const settingReducer = (state = defaultState, action) => {
  switch (action.type) {
    case types.CHANGE_LOCALE_REQUEST:
      return { ...state, locale: action.payload.locale };

    default:
      return state;
  }
};

export default settingReducer;
