import React from "react"
import {injectIntl, FormattedMessage} from "react-intl"
import {getIntlMsg} from "../../constants/utils"


export const renderField = injectIntl(
    ({intl, input, label, placeholder, options, itemSize, labelSize, inputSize, type, required = false, meta: {asyncValidating, touched, error, warning}}) => (
        <div className={itemSize}>
            <label htmlFor={label} className="font-weight-bold">
                <FormattedMessage id={label} defaultMessage={`Label Name`}/>{" "}
                <span className="text-warning">{required ? "*" : null}</span> :
            </label>

            {type === "select" ? (
                    <select {...input} className="form-control" id={label}>
                        {options.map((opt, key) => (
                            <option value={opt.id} key={key}>
                                {opt.name}
                            </option>
                        ))}
                    </select>
                ) : type === "textarea" ? (
                        <div>
                            <div>
                                <textarea {...input} placeholder="Content" rows="10" cols="40" className="form-control"/>
                                {touched && ((error && <span>{error}</span>) || (warning && <span>{warning}</span>))}
                            </div>
                        </div>
                    ) : (
                        <input
                            {...input}
                            className="form-control"
                            id={label}
                            placeholder={getIntlMsg(intl, placeholder)}
                            type={type}
                        />
                    )}

            { touched && error && (
                <div className="">
                    <small className="text-warning" style={{fontSize: "12px"}}>
                        <FormattedMessage id={error} defaultMessage={`Field error`}/>
                    </small>
                </div>
            )}
        </div>
    )
)

//NOTE  Custom common field
export const FileField = injectIntl(
    ({intl, input, label, placeholder, options, itemSize, labelSize, inputSize, type, required = false, meta: {asyncValidating, touched, error, warning}}) => (
        <input  {...input} className="form-control" type={type}/>
    )
)
