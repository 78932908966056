import types from "../type"



/**
------------------------------------------------
NOTE Switch locale pack action creator
-----------------------------------------------
 */


//ANCHOR add message fail
export const changeLocale = payload => ({
  type: types.CHANGE_LOCALE_REQUEST,
  payload
})



