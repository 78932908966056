/**
 * Created by louidorpc on 7/1/20.
 */

import React from 'react';
import {FacebookLikeButton} from '../facebook'


const TopBAr = () => (
    <div className="topbar">
        <div className="upper-topbar">
            <div className="container">
                <div className="row">
                    <div className="col col-sm-6">
                        <p>1ère entreprise au monde avec un engagement à 100%</p>
                    </div>
                </div>
            </div>
        </div>
        <div className="lower-topbar">
            <div className="container">
                <div className="row">
                    <div className="col col-sm-3">
                        <div className="site-logo">
                            <a href="true"><img src="assets/images/clogo.png" alt="true" width="60px"/></a>
                        </div>
                    </div>
                    <div className="col col-sm-9">
                        <div className="contact-info">
                            <div>
                                <i className="fa fa-phone-square"></i>
                                <div className="details">
                                    <p>Appelez-nous</p>
                                    <span><a href="tel:+50931215441">+509 3121-5441 / 3422-0571</a></span>
                                </div>
                            </div>
                            <div>
                                <i className="fa fa-clock-o"></i>
                                <div className="details">
                                    <p>Heures d'ouverture</p>
                                    <span>Lundi - Vendredi : 9h - 17h</span>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);

export default TopBAr;