/**
 * Created by louidorpc on 7/6/20.
 */
import React from 'react'

const RecentNews = () => (

    <section class="blog-section section-padding">
        <div class="container">
            <div class="row">
                <div class="col col-md-8 col-md-offset-2">
                    <div class="section-title-s3">
                        <h2>Articles récents</h2>
                    </div>
                </div>
            </div>
            <div class="row news-grids">
                <div class="col col-md-4 col-xs-6">
                    <div class="grid">
                        <div class="entry-media">
                            <img src="assets/images/articles/1.jpeg" alt/>
                        </div>
                        <div class="entry-body">
                            <div class="entry-header">
                                <h3><a href="#">Concours d'écriture créative </a></h3>
                            </div>
                            <div class="entry-details">
                                <p>Bonjour,<br></br>
                                    CORRECTPRO, votre maison d'édition, encourage les jeunes à prendre des initiatives
                                    et leur tend ses bras pour un plus grand impact.
                                </p>
                            </div>
                            <div class="entry-meta">
                                <a href="/news" class="read-more">Lire plus</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col col-md-4 col-xs-6">
                    <div class="grid">
                        <div class="entry-media">
                            <img src="assets/images/articles/2.jpeg" alt/>
                        </div>
                        <div class="entry-body">
                            <div class="entry-header">
                                <h3><a href="#">CONFINEMENT </a></h3>
                            </div>
                            <div class="entry-details">
                                <p>
                                    La croissance d’un peuple confiné !
                                    Confiné de temps en temps pour des raisons diverses. Déconfiné, le temps de prendre
                                    une bouchée d’oxygène. De confinement en confinement, l’histoire poursuit sa course.

                                </p>
                            </div>
                            <div class="entry-meta">
                                <a href="/news" class="read-more">Lire plus</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col col-md-4 col-xs-6">
                    <div class="grid">
                        <div class="entry-media">
                            <img src="assets/images/articles/5.jpeg" alt className="dets"/>
                        </div>
                        <div class="entry-body">
                            <div class="entry-header">
                                <h3><a href="#"> Le Journal intime de Fréda</a></h3>
                            </div>
                            <div class="entry-details">
                                <p>
                                    « Le Journal intime de Fréda » est un véritable tambour, c'est une oeuvre poétique
                                    aussi chaude que le soleil de midi.
                                    <br></br>C'est un hymne à Fréda et aux femmes de partout!
                                </p>
                            </div>
                            <div class="entry-meta">
                                <a href="/news" class="read-more">Lire plus</a>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-md-12 section-padding">
                    <div class="all-projects col-md-offset-5">
                        <a href="/news" class="theme-btn">Tous les articles</a>
                    </div>
                </div>

            </div>
        </div>
    </section>
)

export  default RecentNews;