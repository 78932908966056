import React from "react";
import {FormattedMessage} from "react-intl";
import {Field, reduxForm} from "redux-form";
import {connect} from 'react-redux'
import {validate} from "./validator";
import {renderField} from "../../common/FormComponents";


const onClearForm = (reset, resetStateContact) => {
    reset('contact');
    resetStateContact();

}

const Form = props => {
    const {handleSubmit, valid, reset, pristine, submitting, msItem, resetStateContact, intl, history, message, form} = props;

    if (message === "success") onClearForm(reset, resetStateContact)
    return (

        <form onSubmit={handleSubmit} id="contact-form" class="form row contact-validation-active" noValidate>
            <div class="col col-xs-12">
                <Field
                    className="form-control"
                    id="option-message"
                    name="fullname"
                    component={renderField}
                    type="text"
                    label="app.label.fullname"
                    placeholder="app.placeholder.fullname"
                />

            </div>
            <div class="col col-xs-12">
                <Field
                    className="form-control"
                    id="option-message"
                    name="email"
                    component={renderField}
                    type="email"
                    label="app.label.email"
                    placeholder="app.placeholder.email"
                />

            </div>

            <div class="col col-xs-12">
                <Field
                    className="form-control"
                    id="option-message"
                    name="phone"
                    component={renderField}
                    type="text"
                    label="app.label.phone"
                    placeholder="app.placeholder.phone"
                />

            </div>

            <div class="col col-xs-12">
                <Field
                    className="form-control"
                    id="option-message"
                    name="message"
                    component={renderField}
                    type="textarea"
                    label="app.label.message"
                    placeholder="app.placeholder.message"
                />

            </div>

            <div class="col col-xs-12 submit-btn">
                <button type="submit"
                        disabled={pristine || submitting}>
                    Envoyé
                </button>
                <div id="loader">
                    <i class="fa fa-refresh fa-spin fa-3x fa-fw"></i>
                </div>
            </div>
            <div class="error-handling-messages">
                <div id="success">Thank you</div>
                <div id="error"> Error occurred while sending email. Please try again later.</div>
            </div>
        </form >

    );
}


const mapStateToProps = (state, props) => (
    {
        initialValues: props.msItem,

    })

export default connect(
    mapStateToProps
)(reduxForm({
    form: 'contact',
    enableReinitialize: true,
    validate,
    //   asyncValidate,

    //   status: true
})(Form));

