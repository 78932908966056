/**
 * Created by louidorpc on 7/18/20.
 */
import React from 'react';
import Form from './form'
import {ContactModel} from '../../constants/model'
import PageTitle from '../pageTitle'


const Contact = props => {

    const {msR: {isLoading, msItem, message, ms}, actions:{sendMessage, resetStateContact}, intl} = props;

    const submit = values => {

        const msM = new ContactModel({...values});
        sendMessage(msM);
    };

    return (
        <div>


          <PageTitle title="Contact" home="Acceuil"/>




            <section class="contact-section section-padding">
                <div class="container">
                    <div class="row">
                        <div class="col col-md-6">
                            <div class="contact-location-map" id="map"></div>
                        </div>
                        <div class="col col-md-6">
                            <div class="section-title-s4">
                                <h2>Entrons en contact</h2>
                                <p>Notre siège social se trouve au 24, Impasse Racine, Santo 11, Croix-des-Bouquets,
                                    Haïti.</p>
                            </div>
                            <div class="contact-form">

                                <Form
                                    onSubmit={submit}
                                    msItem={msItem}
                                    message={message}
                                    intl={intl}
                                    resetStateContact={resetStateContact}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </div>
    )
}

export default Contact;
