/**
 * Created by louidorpc on 7/18/20.
 */
import React from 'react';
import Contact from '../../component/contact';
import PageTitle from '../../component/pageTitle';
import Banner from '../../component/slide/banner'
import {injectIntl} from 'react-intl';


const ContactContainer = props => {

    const {msR, actions, intl} = props;

    return (
        <Contact msR={msR} actions={actions} intl={intl}>
            <Banner>
                <PageTitle title="Contact" home="Accueil"/>
            </Banner>
        </Contact>
    )
}

export default (injectIntl(ContactContainer));

