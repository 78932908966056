import types from "../type"
import {toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


toast.configure()


export const resetStateContact = () => ({
    type: types.RESET_STATE_CONTACT,
    payload: {message: null}
});


export const resetStateContactSuccess = () => ({
    type: types.RESET_STATE_CONTACT_SUCCESS,
    payload: {message: null}
});


export const sendMessage = ms => ({
    type: types.SEND_MESSAGE,
    payload: {isLoading: true, ms: ms}
});


export const sendMessageSuccess = ms => {
    toast.success("Votre message a été envoyé !", {
        position: toast.POSITION.TOP_RIGHT
    });
    return {
        type: types.SEND_MESSAGE_SUCCESS,
        payload: {isLoading: false, ms: ms, message: `success`, msItem: null}
    }

}


export const sendMessageFailled = ms => {
    toast.error(ms, {
        position: toast.POSITION.TOP_RIGHT
    });
    return {
        type: types.SEND_MESSAGE_FAILLED,
        payload: {isLoading: false, message: `error`}
    };
}




