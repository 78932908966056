/**
 * Created by louidorpc on 7/10/20.
 */
import React from 'react'


const Services = () => (
    <section className="services-section-s2 section-padding">
        <div className="container">
            <div className="row">
                <div className="col col-xs-12">
                    <div className="section-title">
                        <h2>Nos services</h2>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col col-xs-12">
                    <div className="services-grid-s2 services-s2-slider">
                        {/*<div className="grid">*/}
                        {/*<div className="icon">*/}
                        {/*<img src="/assets/fonts/written.svg" alt="hsgdjhgdj"/>*/}
                        {/*/!*<i className="fi flaticon-presentation"></i>*!/*/}
                        {/*</div>*/}
                        {/*<div className="details">*/}
                        {/*<h3>Saisie de textes</h3>*/}
                        {/*<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidi*/}
                        {/*dunt ut labore et dolore.</p>*/}
                        {/*<a href="#" className="read-more">Lire plus</a>*/}
                        {/*</div>*/}
                        {/*<div className="hover-grid">*/}
                        {/*<span></span>*/}
                        {/*<span></span>*/}
                        {/*<span></span>*/}
                        {/*</div>*/}
                        {/*</div>*/}
                        <div className="grid">
                            <div className="icon">
                                <img src="/assets/fonts/attendance.svg" alt="hsgdjhgdj"/>
                            </div>
                            <div className="details">
                                <h3>Correction</h3>
                                <p>Cette étape permet d’apporter un regard extérieur à votre ouvrage et de corriger les
                                    éventuelles fautes d’orthographe, de grammaire et de syntaxe. Confier votre
                                    manuscrit à nos biographes est la garantie de publier un livre idéal sur le fond
                                    avec une ligne éditoriale sans faille.</p>
                                <a href="/edition" className="read-more">Lire plus</a>
                            </div>
                            <div className="hover-grid">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="icon">
                                <img src="/assets/fonts/study.svg" alt="hsgdjhgdj"/>
                            </div>
                            <div className="details">
                                <h3>Page de couverture</h3>
                                <p>La couverture d’un livre est un des éléments de marketing les plus importants qui
                                    peut influencer le consommateur. Confier votre manuscrit à nos infographistes est la
                                    garantie d’obtenir une couverture soignée et personnalisée, qui contribuera
                                    fortement à son succès.</p>
                                <a href="#" className="read-more">Lire plus</a>
                            </div>
                            <div className="hover-grid">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        {/*<div className="grid">*/}
                        {/*<div className="icon">*/}
                        {/*<img src="/assets/fonts/translate.svg" alt="hsgdjhgdj"/>*/}
                        {/*</div>*/}
                        {/*<div className="details">*/}
                        {/*<h3>Traduction</h3>*/}
                        {/*<p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incidi*/}
                        {/*dunt ut labore et dolore.</p>*/}
                        {/*<a href="#" className="read-more">Lire plus</a>*/}
                        {/*</div>*/}
                        {/*<div className="hover-grid">*/}
                        {/*<span></span>*/}
                        {/*<span></span>*/}
                        {/*<span></span>*/}
                        {/*</div>*/}
                        {/*</div>*/}
                        <div className="grid">
                            <div className="icon">
                                <img src="/assets/fonts/concept.svg" alt="hsgdjhgdj"/>
                            </div>
                            <div className="details">
                                <h3>Conception</h3>
                                <p>Notre équipe éditoriale s’occupe ensuite de l’harmonisation de votre texte afin de
                                    rendre sa lecture fluide et agréable. Une fois la mise en page effectuée, Pro
                                    Éditions vous envoie votre livre finalisé pour validation ou corrections éventuelles
                                    de votre part. Si le livre correspond à vos attentes, il est édité au format papier
                                    et numérique.</p>
                                <a href="/edition" className="read-more">Lire plus</a>
                            </div>
                            <div className="hover-grid">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>
                        <div className="grid">
                            <div className="icon">
                                <img src="/assets/fonts/print1.svg" alt="hsgdjhgdj"/>
                            </div>
                            <div className="details">
                                <h3>Impression</h3>
                                <p>Nos livres seront d’une qualité imbattable en termes de présentation et contenu. Ils
                                    seront minutieusement traités (fond et forme) avant de passer à une impression à
                                    grand volume</p>
                                <a href="/edition" className="read-more">Lire plus</a>
                            </div>
                            <div className="hover-grid">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </section>

)

export default Services;

