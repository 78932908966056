/**
 * Created by louidorpc on 7/6/20.
 */
import React from 'react'
import PageTitle from '../pageTitle'
import  {FacebookPage,FacebookComment} from '../facebook'


const About = () => (

    <>
    <PageTitle title="Articles" home="Acceuil"/>
    <section class="blog-with-sidebar-section section-padding">
        <div class="container">
            <div class="row">
                <div class="news-grids col col-lg-8 col-md-8">
                    <div class="grid">
                        <div class="entry-media">
                            <img src="assets/images/articles/1.jpeg" alt=""/>
                        </div>
                        <div class="entry-body">
                            <div class="entry-header">
                                <h3><a href="#">Concours d'écriture créative </a></h3>
                            </div>
                            <div class="entry-details">
                                <p>
                                    CORRECTPRO, votre maison d'édition, encourage les jeunes à prendre des initiatives
                                    et leur tend ses bras pour un plus grand impact.
                                </p></div>
                            <div class="entry-meta">
                                <a href="#" class="read-more">Lire plus</a>
                            </div>
                        </div>
                    </div>

                    <div class="grid">
                        <div class="entry-media">
                            <img src="assets/images/articles/2.jpeg" alt=""/>
                        </div>
                        <div class="entry-body">
                            <div class="entry-header">
                                <h3><a href="#">CONFINEMENT </a></h3>
                            </div>
                            <div class="entry-details">
                                <p>
                                    La croissance d’un peuple confiné !
                                    Confiné de temps en temps pour des raisons diverses. Déconfiné, le temps de prendre
                                    une bouchée d’oxygène. De confinement en confinement, l’histoire poursuit sa course.

                                </p>
                                <h6>Dr Maurice Jacques</h6>
                            </div>
                            <div class="entry-meta">
                                <a href="#" class="read-more">Lire plus</a>
                            </div>
                        </div>
                    </div>

                    <div class="grid">
                        <div class="entry-media">
                            <img src="assets/images/articles/5.jpeg" alt=""/>
                        </div>
                        <div class="entry-body">
                            <div class="entry-header">
                                <h3><a href="#">Le Journal intime de Fréda </a></h3>
                            </div>
                            <div class="entry-details">
                                <p>
                                    « Le Journal intime de Fréda » est un véritable tambour, c'est une oeuvre poétique
                                    aussi chaude que le soleil de midi.
                                    <br></br>C'est un hymne à Fréda et aux femmes de partout!
                                </p>
                                <h6>Fabienne L. Lamour</h6>
                            </div>
                            <div class="entry-meta">
                                <a href="#" class="read-more">Lire plus</a>
                            </div>
                        </div>
                    </div>

                    <div class="grid">
                        <div class="entry-media">
                            <img src="assets/images/articles/3.jpeg" alt=""/>
                        </div>
                        <div class="entry-body">
                            <div class="entry-header">
                                <h3><a href="#">Gennsenk </a></h3>
                            </div>
                            <div class="entry-details">
                                <p>
                                    Inèt se timoun ki fèt nan lakou Mouzen. E se byenbonè, li konn sa yo rele batay ant
                                    lakou Mouzen ak Pòs Machan kote menm tibebe yon lane oblije aprann voye wòch pou
                                    defann tèt yo.
                                </p>
                                <h6>Rodolphe Mathurin</h6>
                            </div>
                            <div class="entry-meta">
                                <a href="#" class="read-more">Lire plus</a>
                            </div>
                        </div>
                    </div>
                    <div class="grid">
                        <div class="entry-media">
                            <img src="assets/images/articles/7.jpeg" alt=""/>
                        </div>
                        <div class="entry-body">
                            <div class="entry-header">
                                <h3><a href="#">Kat Pwen Kadino </a></h3>
                            </div>
                            <div class="entry-details">
                                <p>
                                    Nou pa anvi pawòl anlè pran pa sou kesyon sa n ap viv nan yon reyalite bosal k ap
                                    kraze zo n. Nou anvi la. Nou anvi viv, n anvi li liv lavi a, ak tout nanm nou, tout
                                    fòs nou, nan lang pa nou
                                </p>
                                <h6>Mehdi Etienne Chalmers et Inema Jeudi</h6>
                            </div>
                            <div class="entry-meta">
                                <a href="#" class="read-more">Lire plus</a>
                            </div>
                        </div>
                    </div>
                    <div class="grid">
                        <div class="entry-media">
                            <img src="assets/images/articles/6.jpeg" alt=""/>
                        </div>
                        <div class="entry-body">
                            <div class="entry-header">
                                <h3><a href="#">Saisons Noires </a></h3>
                            </div>
                            <div class="entry-details">
                                <p>s’inscrit dans un cri de ralliement contre la politique macabre des dirigeants
                                    haïtiens qui retiennent ce pays prisonnier du temps des saisons noires</p>
                                <h6>Fénique Jasmin</h6>
                            </div>
                            <div class="entry-meta">
                                <a href="#" class="read-more">Lire plus</a>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <FacebookComment/>
                    </div>
                </div>

                <div class="blog-sidebar col col-lg-offset-0 col-lg-3 col-md-4 col-sm-5">
                    <div class="widget category-widget">
                        <h3>catégorie</h3>
                        <ul>
                            <li><a href="#">Politiques <span class="badge">0</span></a></li>
                            <li><a href="#">Romans <span class="badge">0</span></a></li>
                            <li><a href="#">Éducations <span class="badge">0</span></a></li>
                            <li><a href="#">Poésies <span class="badge">0</span></a></li>
                            <li><a href="#">Sociales <span class="badge">0</span></a></li>
                            <li><a href="#">Litéraires <span class="badge">0</span></a></li>
                        </ul>
                    </div>
                    <div class="widget recent-post-widget">
                        <h3>POST RÉCENT</h3>
                        <ul>
                            <li>
                                <div class="post-pic">
                                    <img src="assets/images/articles/1.jpeg" alt=""/>
                                </div>
                                <div class="details">
                                    <h4><a href="#">Concours d'écriture créative</a></h4>
                                    <span>Sept, 2020</span>
                                </div>
                            </li>
                            <li>
                                <div class="post-pic">
                                    <img src="assets/images/articles/2.jpeg" alt=""/>
                                </div>
                                <div class="details">
                                    <h4><a href="#">CONFINEMENT</a></h4>
                                    <span>Juil, 2020</span>
                                </div>
                            </li>
                            <li>
                                <div class="post-pic">
                                    <img src="assets/images/articles/3.jpeg" alt=""/>
                                </div>
                                <div class="details">
                                    <h4><a href="#">Gennsenk</a></h4>
                                    <span>Sept, 2020</span>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div class="widget recent-post-widget">
                        <FacebookPage/>
                    </div>
                    <div class="widget instagram-widget">
                        <h3>Instagram</h3>
                        <ul>
                            <li><a href="https://instagram.com/correctproht?igshid=11nitckbo7l7b"><img
                                src="assets/images/articles/1.jpeg" alt=""/></a></li>
                            <li><a href="https://instagram.com/correctproht?igshid=11nitckbo7l7b"><img
                                src="assets/images/articles/2.jpeg" alt=""/></a></li>
                            <li><a href="https://instagram.com/correctproht?igshid=11nitckbo7l7b"><img
                                src="assets/images/articles/3.jpeg" alt=""/></a></li>
                            <li><a href="https://instagram.com/correctproht?igshid=11nitckbo7l7b"><img
                                src="assets/images/articles/4.jpeg" alt=""/></a></li>
                            <li><a href="https://instagram.com/correctproht?igshid=11nitckbo7l7b"><img
                                src="assets/images/articles/5.jpeg" alt=""/></a></li>
                            <li><a href="https://instagram.com/correctproht?igshid=11nitckbo7l7b"><img
                                src="assets/images/articles/6.jpeg" alt=""/></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </section>

    </>


)

export  default About;