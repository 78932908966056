import {combineReducers} from "redux"
import {reducer as formReducer} from "redux-form"
import settingReducer from "./setting"
import msReducer from "./contact"


export default combineReducers({
    form: formReducer,
    keycloak: (keycloak = {}) => keycloak,
    settingReducer,
    msReducer
});