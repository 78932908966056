import {all} from "redux-saga/effects"
import {settingSaga} from "./setting"
import {msSenderSaga} from "./contact"



export default function* root() {
    yield all([
        ...settingSaga,
        ...msSenderSaga
    ])
}
