import moment from "moment";

moment.locale('fr', {
    months : 'janvier_février_mars_avril_mai_juin_juillet_août_septembre_octobre_novembre_décembre'.split('_'),
    monthsShort : 'janv._févr._mars_avr._mai_juin_juil._août_sept._oct._nov._déc.'.split('_'),
    monthsParseExact : true,
    weekdays : 'dimanche_lundi_mardi_mercredi_jeudi_vendredi_samedi'.split('_'),
    weekdaysShort : 'dim._lun._mar._mer._jeu._ven._sam.'.split('_'),
    weekdaysMin : 'Di_Lu_Ma_Me_Je_Ve_Sa'.split('_'),
    weekdaysParseExact : true,
    longDateFormat : {
        LT : 'HH:mm',
        LTS : 'HH:mm:ss',
        L : 'DD/MM/YYYY',
        LL : 'D MMMM YYYY',
        LLL : 'D MMMM YYYY HH:mm',
        LLLL : 'dddd D MMMM YYYY HH:mm'
    },
    calendar : {
        sameDay : '[Aujourd’hui à] LT',
        nextDay : '[Demain à] LT',
        nextWeek : 'dddd [à] LT',
        lastDay : '[Hier à] LT',
        lastWeek : 'dddd [dernier à] LT',
        sameElse : 'L'
    },
    relativeTime : {
        future : 'dans %s',
        past : 'il y a %s',
        s : 'quelques secondes',
        m : 'une minute',
        mm : '%d minutes',
        h : 'une heure',
        hh : '%d heures',
        d : 'un jour',
        dd : '%d jours',
        M : 'un mois',
        MM : '%d mois',
        y : 'un an',
        yy : '%d ans'
    },
    dayOfMonthOrdinalParse : /\d{1,2}(er|e)/,
    ordinal : function (number) {
        return number + (number === 1 ? 'er' : 'e');
    },
    meridiemParse : /PD|MD/,
    isPM : function (input) {
        return input.charAt(0) === 'M';
    },
    // In case the meridiem units are not separated around 12, then implement
    // this function (look at locale/id.js for an example).
    // meridiemHour : function (hour, meridiem) {
    //     return /* 0-23 hour, given meridiem token and hour 1-12 */ ;
    // },
    meridiem : function (hours, minutes, isLower) {
        return hours < 12 ? 'AM' : 'PM';
    },
    week : {
        dow : 1, // Monday is the first day of the week.
        doy : 4  // Used to determine first week of the year.
    }
});

const message = {

    //NOTE
    "app.label.employee_finger": "Empreinte Digitale",
    "app.label.employee_picture": "Photo Employé",
    "app.label.employee_finger_description": "Index Gauche et Droite",
    "app.label.employee_picture_description": "Choisissez une photo",
    "app.label.show_employee_list": "Liste des employés",
    "app.label.profile": "Profil",
    "app.label.employee": "Employé",
    "app.label.user": "Utilisateurs",
    "app.label.logout": "Déconnexion",
    "app.label.copyright": "Tous droits Réservés",
    "app.label.version": "Version",
    "app.label.dashboard": "Tableau de bord",
    "app.label.please_fill_the_required_fields": "Veuillez remplir les champs obligatoires",
    "app.label.notification_date": "Date de notification",
    "app.label.save": `Sauvegarder`,
    "app.label.add_new": "Enregistrer Nveau.",
    "app.label.validation_date": "Date de validation",
    "app.label.field_required": "Veuillez saisir une valeur",
    "app.label.must_have_at_least_3": "Nombre de caractères minimum 3",
    "app.label.must_have_at_least_10": "Nombre de caractères minimum 10",
    "app.label.must_have_at_last_10": "Nombre de caractères maximum 10",
    "app.label.must_have_at_last_100": "Nombre de caractères maximum 100",
    "app.label.entity_type_exists": "Ce type existe déja",
    "app.label.greetings": "Salut",
    "app.label.occupation": "Fonction",
    "app.label.employee_table_title": "Liste des employés",
    "app.label.employee_table_description": "Recherche et gestion des employés",


    //NOTE  Actions labels
    "app.label.detail": "Détails",
    "app.label.edit": "Editer",
    "app.label.delete": "Supp.",
    "app.label.cancel": "Annuler",


    //NOTE  Form Fields labels
    "app.label.check_in": "Check-In",
    "app.label.check_out": "Check-Out",
    "app.label.left_index": "Index Gauche",
    "app.label.right_index": "Index Droite",
    "app.label.left_or_right_index": "Index Gauche ou Droite",
    "app.label.scanner_attached_failed": "Assurez-vous que le lecteur est branché",
    "app.label.employee_form": "Informations signalétiques",
    "app.label.employee_form_description": "Les étiquettes avec * sont obligatoires",
    "app.label.code": "Code",
    "app.label.name": "Nom",
    "app.label.description": "Description",
    "app.label.phone": "Téléphone",
    "app.label.email": "Couriel",
    "app.label.address": "Adresse",
    "app.label.firstname": "Prénom",
    "app.label.fullname": "Nom complet",
    "app.label.lastname": "Nom",
    "app.label.function": "Fonction",
    "app.label.department": "Département",
    "app.label.position": "Position",
    "app.label.gender": "Sexe",
    "app.label.bloodType": "Groupe sanguin",
    "app.label.startDate": "Date d'entrée",
    "app.label.foodEligible": "Accès à la nourriture ?",
    "app.label.male": "Masculin",
    "app.label.female": "Féminin",
    "app.label.employee.list": "Liste des employés",


    //NOTE Form fields placeholders
    "app.placeholder.code": "Inserez code",
    "app.placeholder.validation_date": "Inserez date de validation",
    "app.placeholder.notification_date": "Inserez date de notification",
    "app.placeholder.name": "Inserez un nom",
    "app.placeholder.firstname": "Inserez prénom",
    "app.placeholder.lastname": "Inserez nom",
    "app.placeholder.function": "Choisissez une fonction",
    "app.placeholder.department": "Choisissez un département",
    "app.placeholder.description": "Inserez une description",
    "app.placeholder.cin": "Inserez un CIN",
    "app.placeholder.nif": "Inserez un NIF",
    "app.placeholder.address": "Inserez adresse",
    "app.placeholder.phone": "Téléphone",
    "app.placeholder.message": "Tapez votre Message ici...",


    //DEPARTMENT
    "app.label.department": "Departement",
    "app.label.cin": "CIN",
    "app.label.nif": "NIF",
    "app.label.department.name": "Nom du departement",
    "app.department.placeholder.name": "Inserez le nom du departement",
    "app.label.department_table_title": "Liste des departements",


    "app.label.position": "Position",
    "app.label.position.name": "Position",
    "app.label.placeholder.position": "Inserez le nom de la position",
    "app.label.shutName": "Nom raccourci",
    "app.label.placeholder.shutName": "Inserez le nom raccourci de la position",
    "app.position.placeholder.name": "Inserez la position",
    "app.label.position_table_title": "Liste des positions",
    "app.label.stat.prensent": "PRESENT",
    "app.label.stat.today": "Aujourd'hui",
    "app.label.stat.visitor": "VISITEUR",
    "app.label.stat.abscent": "ABSENT",
    "app.label.stat.employee": "EMPLOYES",
    "app.label.stat.list": "Liste des employés présents aujourd'hui",
    "app.label.add_employee": "Ajouter un nouveau employé",
    "app.label.identification": "Identification",
    "app.label.search": "Recherche",
    "app.label.info.search": "Rechercher par code, nom ou prenom",
    "app.label.current_location_failed": "Assurez-vous que la location courante est définie ou contacter l'administrateur du système.",
    "label.fullName":"Nom complet",
    "label.checkin":"Entrée",
    "label.checkout":"Sortie",
    "app.label.employeeInfo":"Information sur l'employé",
    "app.label.calendar":"Calendrier de présence",
    "app.placeholder.email":"E-mail",
    "app.placeholder.fullname":"Nom complet",
    "app.placeholder.message":"Taper votre message ici...",
    "app.label.message":"Message",


};


export default message;