/**
 * Created by louidorpc on 7/6/20.
 */
import React from 'react'

const Newsletter = () => (

    <section class="newsletter-section">
        <div class="container">
            <div class="row">
                <div class="col col-lg-8 col-md-7  col-md-offset-2">
                    <h2><span>Inscrivez-vous avec votre e-mail </span> à notre newsletter</h2>
                    <form>
                        <div>
                            <input type="text" class="form-control"/>
                            <button>Souscrire</button>
                        </div>
                    </form>
                </div>
            </div>
            {/*<div class="newsletter-man">*/}
                {/*<img src="assets/images/DSC_9656.jpg" alt  height="399"/>*/}
            {/*</div>*/}
        </div>
    </section>
)

export  default Newsletter;