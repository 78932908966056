/**
 * Created by louidorpc on 7/18/20.
 */
import React from 'react';


const Banner = ({children}) => (
    <div>
        {children}
    </div>
)


export default  Banner;