import { takeLatest, put } from "redux-saga/effects"
import actions from "../actions/creator"
import types from "../actions/type"

export const handleSwitchLocale = function*(action) {
  try {
    const { payload } = action
    yield put(actions.switchLocaleSuccess(payload))
  } catch (error) {
    yield put(actions.switchLocaleFailure())
  }
}



export const settingSaga = [
  takeLatest(types.CHANGE_LOCALE_REQUEST, handleSwitchLocale),
]
