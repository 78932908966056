import React from "react"
import {connect} from "react-redux"
import {IntlProvider} from "react-intl"
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {adminRoutes as routes} from "./constants/router";
import messages from "./constants/messages"
import Header from './container/header';
import Footer from './component/footer'
import createHistory from 'history/createBrowserHistory';
import ScriptTag from 'react-script-tag';

class BaseLayout extends React.Component {

    constructor(props) {
        super(props)
    }

    componentWillMount() {


    }

    render() {
        const {locale} = this.props;
        const history = createHistory();
        let path = history.location.pathname;



        return (
            <IntlProvider locale={locale} messages={messages[locale]}>
                <Router>
                    {/*<ScriptTag type="text/javascript" src="assets/js/jquery.min.js"/>*/}
                    {/*<ScriptTag type="text/javascript" src="assets/js/bootstrap.min.js"/>*/}
                    {/*<ScriptTag type="text/javascript" src="assets/js/jquery-plugin-collection.js"/>*/}
                    {/*<ScriptTag type="text/javascript" src="assets/js/script.js"/>*/}

                        <Header path={path}/>
                        <Switch>
                            {routes.map((route, index) => (
                                <Route key={index} path={route.path} exact={route.exact} component={route.navbar}/>
                            ))}
                        </Switch>
                        <Footer/>

                </Router>

            </IntlProvider >
        )
    }
}

const mapStateToProps = ({settingReducer}) => ({
    locale: settingReducer.locale
})

export default connect(
    mapStateToProps,
    null
)(BaseLayout)

