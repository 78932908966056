/**
 * Created by louidorpc on 7/1/20.
 */
import React from 'react';
import  TopBar from '../../component/topbar';
import  MainMenu from '../../component/menu';

const Header = ({path}) => {
    return (
        <header id="header" className="site-header header-style-2">
            <TopBar/>
            <MainMenu path={path}/>
        </header>
    )
}

export default Header;