import {put, take, call, takeLatest} from "redux-saga/effects";
import  * as actions from "../actions/creator/contact";
import types from "../actions/type";
import * as api from "../services/conatct";


export const handleResetStateContact = function*() {
    yield put(actions.resetStateContactSuccess())
};


export const handleSendMessage = function*(action) {

    try {
        const {ms} = action.payload;
        const savedMs = yield call(api.sendMs, ms);
        if(savedMs.data.response==="success"){
            yield put(actions.sendMessageSuccess(savedMs.data))
        }else{
            yield put(actions.sendMessageFailled(savedMs.data.response))
        }

    } catch (error) {
        yield put(actions.sendMessageFailled())
    }
};


export const msSenderSaga = [


    takeLatest(types.SEND_MESSAGE, handleSendMessage),
];
