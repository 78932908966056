import types from "../actions/type"

const defaultState = {
    isLoading: true,
    ms: null,
    message: null,
    msItem: null,
}

const msReducer = (state = defaultState, action) => {
    switch (action.type) {


        case types.RESET_STATE_CONTACT:
            return {...state, message: action.payload.message};

        case types.RESET_STATE_CONTACT_SUCCESS:
            return {...state, message: action.payload.message, isLoading: true, msItem: null};

        case types.SEND_MESSAGE:
            return {
                ...state,
                ms: action.payload.ms,
                isLoading: action.payload.isLoading
            };


        case types.SEND_MESSAGE_SUCCESS:
            return {
                ...state,
                isLoading: action.payload.isLoading,
                msItem: action.payload.msItem,
                message: action.payload.message,
            };


        case types.SEND_MESSAGE_FAILLED:
            return {
                ...state,
                isLoading: action.payload.isLoading,
                message: action.payload.message
            };


        default:
            return state

    }
}


export default msReducer
