import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from "./store/";
import {Provider} from "react-redux";
import {render} from "react-dom";
import ScriptTag from 'react-script-tag';

const store = configureStore();





render(
    <Provider store={store}>

        <App />
        {/*<ScriptTag type="text/jsx" src="assets/js/jquery-plugin-collection.js"/>*/}
        {/*<ScriptTag type="text/jsx" src="assets/js/script.js"/>*/}
        {/*<ScriptTag type="text/jsx" src="assets/js/slide.js"/>*/}
    </Provider>,
    document.getElementById("root")
);





// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
