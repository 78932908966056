/**
 * Created by louidorpc on 7/1/20.
 */
import React from 'react';

class Slide extends React.Component {


    render() {

        return (

            <section className="hero hero-style-2 hero-slider-wrapper">
                <div className="hero-slider">
                    <div className="slide">
                        <img src="assets/images/slider/slide-7.jpeg" alt="true" className="slider-bg"/>
                        <div className="container">
                            <div className="row">
                                <div className="col col-md-8 col-sm-9 slide-caption">
                                    <h2>Écrire, Lire, Apprendre</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="slide">
                        <img src="assets/images/news1.jpeg" alt="true" className="slider-bg"/>
                        <div className="container">
                            <div className="row">
                                <div className="col col-md-8 col-sm-9 slide-caption">
                                    <h2>La maison d'édition qui matérialise votre inspiration</h2>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="slide">
                        <img src="assets/images/slider/slide-7.jpeg" alt="true" className="slider-bg"/>
                        <div className="container">
                            <div className="row">
                                <div className="col col-md-8 col-sm-9 slide-caption">
                                    <h2>Une lueur qui illumine le néant</h2>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}
export default Slide;