import {connect} from "react-redux"
import {bindActionCreators} from "redux"
import actions from "../actions/creator"
import Conatcts from "./contact"
import { injectIntl } from 'react-intl';

const mapStateToProps = ({msReducer, configReducer,intl,history }) => ({
    msR: msReducer,
    intl:intl,
    history:history

});

const mapDispatchToProps = dispatch => ({
    actions: bindActionCreators(actions, dispatch)
});

export default (injectIntl(connect(mapStateToProps, mapDispatchToProps)(Conatcts)))
