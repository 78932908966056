/**
 * Created by louidorpc on 7/6/20.
 */
import React from 'react'
import {FacebookPage, FacebookComment} from '../facebook';

class AboutHome extends React.Component {

    render() {

        return (<>
            <section class="section-padding about-section-s2">
                <div class="container">

                    <div class="row">
                        <div class="col col-md-7">
                            <div class="about-text se">
                                <h3>Mission</h3>
                                <p>
                                    Création en Haïti d'une génération de jeunes écrivains.
                                </p>
                                <h3>Description de CORRECTPRO</h3>
                                <p style={{textAlign: 'justify!important'}}>CORRECTPRO est une entreprise fondée le 10
                                    juin 2020
                                    qui évolue dans le secteur
                                    Édition/Imprimerie. Elle est reconnue par l'État haïtien et est dûment enregistrée
                                    au
                                    ministère du Commerce et de l’Industrie. Nous avons aussi notre première patente
                                    mais de
                                    forme juridique entreprise individuelle. Cette entreprise se veut un espace
                                    éditorial qui
                                    héberge des auteurs de toute part désireux d'expérimenter autrement les travaux
                                    littéraires.
                                    C'est dans cette optique que le slogan « Lire, Écrire et Apprendre » a été adopté.
                                    CORRECTPRO a la prétention de venir combler utilement ce secteur jugé en manque
                                    d'exploitation ou mal exploité. En ce sens, il a été décidé que CORRECTPRO vienne
                                    aider dans
                                    la dynamisation d'une autre culture éditoriale en Haïti.</p>
                                <a href="/about" className="theme-btn">Détails</a>

                            </div>
                        </div>

                        <div class="col col-md-5">
                            <div className="video">
                                <img src="assets/images/about.jpeg" alt=""/>
                                <a href="/assets/correctpresentation.mp4?autoplay=1" class="video-btn"
                                   data-type="iframe"><i class="fa fa-play"></i></a>
                            </div>

                            <div className="col-md-12 section-padding">
                                <FacebookPage/>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            </>
        )
    }

}
export  default AboutHome;