/**
 * Created by louidorpc on 7/18/20.
 */

import React from 'react';
import {Link} from 'react-router-dom'
const PageTitle = ({title, home}) => (

    <section class="page-title">
        <div class="container">
            <div class="row">
                <div class="col col-xs-12">
                    <h2>{title}</h2>
                    <ol class="breadcrumb">
                        <li>
                            <Link to="/">
                                <a href="index.html">{home}</a>
                            </Link>
                        </li>
                        <li>{title}</li>
                    </ol>
                </div>
            </div>
        </div>
    </section>
)

export default PageTitle;