const types = {

    CHANGE_LOCALE_REQUEST: "CHANGE_LOCALE_REQUEST",

    SEND_MESSAGE: "SEND_MESSAGE",
    SEND_MESSAGE_SUCCESS: "SEND_MESSAGE_SUCCESS",
    SEND_MESSAGE_FAILLED: "SEND_MESSAGE_FAILLED",
    RESET_STATE_CONTACT: "RESET_STATE_CONTACT",
    RESET_STATE_CONTACT_SUCCESS: "RESET_STATE_CONTACT_SUCCESS",



};

export default types;
