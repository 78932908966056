/**
 * Created by louidorpc on 7/18/20.
 */
import React from 'react';
import Conception from '../../component/conception';
import Funcat from '../../component/funfact';
import Pathner from '../../component/pathners'
import {injectIntl} from 'react-intl';


const ConceptionContainer = props => {


    return (
        <>
        <Conception/>
        <Funcat/>
        <Pathner/>
        </>
    )
}

export default (injectIntl(ConceptionContainer));

