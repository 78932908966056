/**
 * Created by louidorpc on 7/15/20.
 */
import React from 'react'


const Footer = () => (
    <footer class="site-footer">
        <div class="upper-footer">
            <div class="container">
                <div class="row">
                    <div class="col col-md-4 col-sm-6">
                        <div class="widget about-widget">
                            <div class="footer-logo"><img src="assets/images/clogo.png" alt width="100px"/></div>
                            <p>CORRECTPRO, votre maison d'édition, se fait le plaisir de mettre à la portée des enfants
                                des livres pouvant les aider à bien grandir.</p>
                            <ul class="contact-info">
                                <li><i class="fa fa-phone"></i> <a href="tel:+5093121-5441">+509 3422-0571 /
                                    3121-5441</a></li>
                                <li><i class="fa fa-envelope"></i><a href="mailto:editions@correctpro.net">
                                    editions@correctpro.net</a></li>
                                <li><i class="fa fa-home"></i> 24, Impasse Racine, Santo 11, Croix-des-Bouquets,
                                    Haïti<br></br> / Juvénat, Juvénat (Pétion-Ville,Haiti)
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div class="col col-md-2 col-sm-6">
                        <div class="widget links-widget">
                            <h3>Liens</h3>
                            <ul>
                                <li><a href="/">Acceuil</a></li>
                                <li><a href="/about">A propos</a></li>
                                <li><a href="/edition">Conception</a></li>
                                <li><a href="/edition">Impression</a></li>
                                <li><a href="#">Auteur</a></li>
                                <li><a href="/contact">Contact</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="col col-md-2 col-sm-6">
                        <div class="widget links-widget">
                            <h3>Autres liens</h3>
                            <ul>
                                <li><a href="/edition">Édition</a></li>
                                <li><a href="/edition">Saisie de textes</a></li>
                                <li><a href="/edition">Correction</a></li>
                                <li><a href="/edition">Mise en page</a></li>
                                <li><a href="/edition">Traduction</a></li>

                            </ul>
                        </div>
                    </div>


                    <div class="col col-md-3 col-sm-6">
                        <div class="widget twitter-feed-widget">
                            <h3>Tweets recents</h3>
                            <ul>
                                <li>
                                    <div class="text">
                                        <p>CorrectPro est votre choix en matière d'édition et de publication.
                                        </p>
                                    </div>
                                    <div class="info-box">
                                        <i class="fa fa-twitter"></i>
                                        <strong><a href="https://twitter.com/pro_correct?s=21">@Louidor Jean Paul</a></strong>
                                    </div>
                                </li>
                                <li>
                                    <div class="text">
                                        <p>CORRECTPRO, votre maison d'édition, se fait le plaisir de mettre à la portée
                                            des enfants des livres pouvant les aider à bien grandir.
                                        </p>
                                    </div>
                                    <div class="info-box">
                                        <i class="fa fa-twitter"></i>
                                        <strong><a href="https://twitter.com/pro_correct?s=21">@Jeff</a></strong>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="copyright-info">
            <div class="container">
                <div class="row">
                    <div class="col col-xs-6">
                        <div class="copyright-area">
                            <p>2020 &copy; Tous droits réservés par <a href="tel:+50940410517">JPL-Solutions</a>
                            </p>
                        </div>
                    </div>
                    <div class="col col-xs-6">
                        <div class="footer-social">
                            <span>Suivez nous: </span>
                            <ul class="social-links">
                                <li><a href="https://www.facebook.com/CorrectProHT/"><i class="fa fa-facebook-square"></i></a></li>
                                <li><a href="https://twitter.com/pro_correct?s=21"><i class="fa fa-twitter-square"></i></a></li>
                                <li><a href="https://instagram.com/correctproht?igshid=11nitckbo7l7b"><i class="fa fa-instagram"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
);

export default Footer;