import axios from "axios"
import {app} from '../constants/config'


const instance = axios.create({
    baseURL: app.BaseURL,
    timeout: 100000,
});

export const sendMs = ms => {
    console.info(ms, 'new message');
    return instance.post(`/contact/create/index.php`, ms)
};