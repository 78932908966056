export class ContactModel {


    constructor({fullname, email, phone, message}) {
        this.fullname = fullname;
        this.email = email;
        this.phone = phone;
        this.message = message;
    }


}

