/**
 * Created by louidorpc on 7/1/20.
 */
import React from 'react'
import {Link, NavLink} from "react-router-dom"

class Menu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            path: props.path
        }
    }


    switchMenu = name => {

        if (name === '/') {
            this.setState({path: '/'})
        }
        else if (name === '/about') {
            this.setState({path: '/about'})
        }
        else if (name === '/edition') {
            this.setState({path: '/edition'})
        } else if (name === '/conception') {
            this.setState({path: '/conception'})
        }
        else if (name === '/news') {
            this.setState({path: '/news'})
        } else if (name === '/contact') {
            this.setState({path: '/contact'})
        }
    }

    render() {

        const {path}=this.state;

        return (
            <nav className="navigation navbar navbar-default">
                <div className="container">
                    <div className="navbar-header">
                        <button type="button" className="open-btn">
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                            <span className="icon-bar"></span>
                        </button>
                    </div>
                    <div id="navbar" className="navbar-collapse collapse navigation-holder">
                        <button className="close-navbar"><i className="fa fa-close"></i></button>
                        <ul className="nav navbar-nav">
                            <li>
                                <Link to="/"
                                   className={path === '/' ? `actives` : null} onClick={() => this.switchMenu('/')}>Accueil</Link>
                            </li>
                            <li>
                                <Link to="/about" onClick={() => this.switchMenu('/about')}
                                   className={path === '/about' ? `actives` : null}>À propos</Link>
                            </li>
                            <li className="menu-item-has-children">
                                <a href="/edition" onClick={() => this.switchMenu('/edition')}
                                   className={path === '/edition' ? `actives` : null}>Édition</a>
                                <ul className="sub-menu">
                                    <li><a href="/edition" onClick={() => this.switchMenu('/edition')}>Saisie de
                                        textes</a></li>
                                    <li><a href="/edition" onClick={() => this.switchMenu('/edition')}>Correction</a>
                                    </li>
                                    <li><a href="/edition" onClick={() => this.switchMenu('/edition')}>Mise en page</a>
                                    </li>
                                    <li><a href="/edition" onClick={() => this.switchMenu('/edition')}>Traduction</a>
                                    </li>


                                </ul>
                            </li>
                            <li>
                                <a href="/conception" onClick={() => this.switchMenu('/conception')}
                                   className={path === '/conception' ? `actives` : null}>Conception</a>
                            </li>
                            {/*<li className="menu-item-has-children">*/}
                            {/*<a href="/edition">Impression</a>*/}
                            {/*</li>*/}
                            <li>
                                <a href="/news" onClick={() => this.switchMenu('/news')}
                                   className={path === '/news' ? `actives` : null}>Articles</a>
                            </li>
                            <li>
                                <a href="/contact" onClick={() => this.switchMenu('/contact')}
                                   className={path === '/contact' ? `actives` : null}>Contact</a>
                            </li>
                        </ul>
                    </div>

                </div>
            </nav>
        )
    }
}


export default Menu;