/**
 * Created by louidorpc on 7/18/20.
 */
import React from 'react';
import News from '../../component/news';
import Funcat from '../../component/funfact';
import Banner from '../../component/slide/banner'
import Pathner from '../../component/pathners'
import {injectIntl} from 'react-intl';


const AboutContainer = props => {


    return (
        <>
        <News/>
        <Funcat/>
        <Pathner/>
        </>
    )
}

export default (injectIntl(AboutContainer));

