import React from "react"
import Home from "../container/home"
import Contact from "../container/ContactContainer"
import About from "../container/about"
import News from "../container/news"
import Conception from "../container/conception"
import Edition from "../container/edition"


export const adminRoutes = [
    {
        path: "/",
        exact: true,
        navbar: () => <Home />
    },
    {
        path: "/contact",
        exact: true,
        navbar: () => <Contact />
    },
    {
        path: "/about",
        exact: true,
        navbar: () => <About />
    },
    {
        path: "/news",
        exact: true,
        navbar: () => <News />
    },
    {
        path: "/conception",
        exact: true,
        navbar: () => <Conception />
    },
    {
        path: "/edition",
        exact: true,
        navbar: () => <Edition />
    }
]

