/**
 * Created by louidorpc on 7/6/20.
 */
import React from 'react'

const FunFact = () => (
    <section className="fun-fact">
        <div className="container">
            <div className="row start-count">
                <div className="col col-md-3 col-xs-6">
                    <div className="grid">
                        <div className="icon">
                            <i className="fi flaticon-handshake"></i>
                        </div>
                        <div className="info">
                            <h3 className="counter" data-count="10">10</h3>
                            <span>Clients satisfaits</span>
                        </div>
                    </div>
                </div>
                <div className="col col-md-3 col-xs-6">
                    <div className="grid">
                        <div className="icon">
                            <i className="fi flaticon-presentation"></i>
                        </div>
                        <div className="info">
                            <h3 className="counter" data-count="15">00</h3>
                            <span>Projets</span>
                        </div>
                    </div>
                </div>
                <div className="col col-md-3 col-xs-6">
                    <div className="grid">
                        <div className="icon">
                            <i className="fi flaticon-team"></i>
                        </div>
                        <div className="info">
                            <h3 className="counter" data-count="4">00</h3>
                            <span>Nos dirigeants</span>
                        </div>
                    </div>
                </div>
                <div className="col col-md-3 col-xs-6">
                    <div className="grid">
                        <div className="icon">
                            <i className="fi flaticon-people"></i>
                        </div>
                        <div className="info">
                            <h3 className="counter" data-count="4">00</h3>
                            <span>Partenaires </span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
)

export default FunFact;