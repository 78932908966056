/**
 * Created by louidorpc on 7/6/20.
 */
import React from 'react'
import PageTitle from '../pageTitle'


const About = () => (

    <>
    <PageTitle title="Conception" home="Acceuil"/>
    <section class="projects-section section-padding">
        <div class="container">
            <div class="row">
                <div class="col col-xs-12">
                    <div class="section-title">
                        <h2>Nos réalisations</h2>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col col-xs-12 sortable-gallery">
                    <div class="gallery-filters projects-list">
                        <ul>
                            <li><a data-filter="*" href="#" class="current">Tous</a></li>
                            <li><a data-filter=".business" href="#">Poésie</a></li>
                            <li><a data-filter=".finance" href="#">Essai</a></li>
                            <li><a data-filter=".consulting" href="#">Nouvelle</a></li>
                            <li><a data-filter=".events" href="#">Roman</a></li>
                            <li><a data-filter=".events" href="#">Auto-biographie</a></li>
                        </ul>
                    </div>

                    <div class="gallery-container projects-grid" style={{position: 'relative', height: '390.334px'}}>
                        <div class="grid business consulting" style={{position: 'absolute', left: '0px', top: '0px'}}>
                            <div class="inner">
                                <div class="img-holder">
                                    <img src="assets/images/articles/9.jpeg" alt=""/>
                                </div>
                                <div class="details">
                                    <div class="details-inner">
                                        <ul>
                                            <li><a href="assets/images/articles/9.jpeg" class="fancybox"><i
                                                class="fa fa-search"></i></a></li>
                                            <li><a href="#"><i class="fa fa-link"></i></a></li>
                                        </ul>
                                        <h3>PNH</h3>
                                        <p class="cat">25 Ans</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="grid business finance" style={{position: 'absolute', left: '287px', top: '0px'}}>
                            <div class="inner">
                                <div class="img-holder">
                                    <img src="assets/images/articles/2.jpeg" alt=""/>
                                </div>
                                <div class="details">
                                    <div class="details-inner">
                                        <ul>
                                            <li><a href="assets/images//articles/2.jpeg" class="fancybox"><i
                                                class="fa fa-search"></i></a></li>
                                            <li><a href="#"><i class="fa fa-link"></i></a></li>
                                        </ul>
                                        <h3>Confinement</h3>
                                        <p class="cat">Dr Maurice Jacques</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="grid events" style={{position: 'absolute', left: '575px', top: '0px'}}>
                            <div class="inner">
                                <div class="img-holder">
                                    <img src="assets/images/articles/3.jpeg" alt=""/>
                                </div>
                                <div class="details">
                                    <div class="details-inner">
                                        <ul>
                                            <li><a href="assets/images/articles/3.jpeg" class="fancybox"><i
                                                class="fa fa-search"></i></a></li>
                                            <li><a href="#"><i class="fa fa-link"></i></a></li>
                                        </ul>
                                        <h3>Gennsenk</h3>
                                        <p class="cat">Rodolphe Mathurin</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="grid event finance consulting"
                             style={{position: 'absolute', left: '862px', top: '0px'}}>
                            <div class="inner">
                                <div class="img-holder">
                                    <img src="assets/images/articles/10.jpeg" alt=""/>
                                </div>
                                <div class="details">
                                    <div class="details-inner">
                                        <ul>
                                            <li><a href="assets/images/articles/10.jpeg" class="fancybox"><i
                                                class="fa fa-search"></i></a></li>
                                            <li><a href="#"><i class="fa fa-link"></i></a></li>
                                        </ul>
                                        <h3>Gouyad Legede</h3>
                                        <p class="cat">Jeudinema</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="grid finance events business"
                             style={{position: 'absolute', left: '0px', top: '195px'}}>
                            <div class="inner">
                                <div class="img-holder">
                                    <img src="assets/images/articles/5.jpeg" alt=""/>
                                </div>
                                <div class="details">
                                    <div class="details-inner">
                                        <ul>
                                            <li><a href="assets/images/articles/5.jpeg" class="fancybox"><i
                                                class="fa fa-search"></i></a></li>
                                            <li><a href="#"><i class="fa fa-link"></i></a></li>
                                        </ul>
                                        <h3>Le Journal intime de Fréda </h3>
                                        <p class="cat">Fabienne L. Lamour</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="grid consulting finance business"
                             style={{position: 'absolute', left: '287px', top: '195px'}}>
                            <div class="inner">
                                <div class="img-holder">
                                    <img src="assets/images/articles/6.jpeg" alt=""/>
                                </div>
                                <div class="details">
                                    <div class="details-inner">
                                        <ul>
                                            <li><a href="assets/images/articles/6.jpeg" class="fancybox"><i
                                                class="fa fa-search"></i></a></li>
                                            <li><a href="#"><i class="fa fa-link"></i></a></li>
                                        </ul>
                                        <h3>Saisons Noires</h3>
                                        <p class="cat">Fénique Jasmin</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="grid events consulting" style={{position: 'absolute', left: '575px', top: '195px'}}>
                            <div class="inner">
                                <div class="img-holder">
                                    <img src="assets/images/articles/7.jpeg" alt=""/>
                                </div>
                                <div class="details">
                                    <div class="details-inner">
                                        <ul>
                                            <li><a href="assets/images/articles/7.jpeg" class="fancybox"><i
                                                class="fa fa-search"></i></a></li>
                                            <li><a href="#"><i class="fa fa-link"></i></a></li>
                                        </ul>
                                        <h3>Kat Pwen Kadino </h3>
                                        <p class="cat">Mehdi Etienne Chalmers et Inema Jeudi</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="grid business finance" style={{position: 'absolute', left: '862px', top: '195px'}}>
                            <div class="inner">
                                <div class="img-holder">
                                    <img src="assets/images/articles/8.jpeg" alt=""/>
                                </div>
                                <div class="details">
                                    <div class="details-inner">
                                        <ul>
                                            <li><a href="assets/images/articles/8.jpeg" class="fancybox"><i
                                                class="fa fa-search"></i></a></li>
                                            <li><a href="#"><i class="fa fa-link"></i></a></li>
                                        </ul>
                                        <h3>Responsabilité de communicateur face à  l'auditoire</h3>
                                        <p class="cat">Jean Marc Sénatus</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>


)

export  default About;