/**
 * Created by louidorpc on 7/1/20.
 */
import  React from 'react'
import Slide from '../../component/slide';
import AboutHome from '../../component/about/home';
import FunFact from '../../component/funfact';
import Services from '../../component/services';
import Newsletter from '../../component/newsletter';
import RecentNews from '../../component/recentnews';
import Pathners from '../../component/pathners';


class Home extends React.Component {
    componentDidMount(){
        // if(!window.location.hash) {
        //     window.location = window.location + '#home';
        //     window.location.reload();
        // }else{
        //     //Init stuff here
        // }
    }

    render() {

        return (<>
            <Slide/>
            <AboutHome/>
            <FunFact/>
            <Services/>
            <Newsletter/>
            <RecentNews/>
            <Pathners/>
            </>
        )
    }
}

export default Home;